import gallery1 from "../assets/gallery/1.jpg";
import gallery2 from "../assets/gallery/2.jpg";
import gallery3 from "../assets/gallery/3.jpg";
import gallery4 from "../assets/gallery/4.jpg";
import gallery5 from "../assets/gallery/5.jpg";
import gallery6 from "../assets/gallery/6.jpg";
import gallery7 from "../assets/gallery/7.jpg";
import gallery8 from "../assets/gallery/8.jpg";
import gallery9 from "../assets/gallery/9.jpg";
import gallery10 from "../assets/gallery/10.jpg";
import gallery11 from "../assets/gallery/11.jpg";
import gallery12 from "../assets/gallery/12.jpg";
import gallery13 from "../assets/gallery/13.jpg";
import gallery14 from "../assets/gallery/14.jpg";
import gallery15 from "../assets/gallery/15.jpg";
import gallery16 from "../assets/gallery/16.jpg";
import gallery17 from "../assets/gallery/17.jpg";
import gallery18 from "../assets/gallery/18.jpg";
import gallery19 from "../assets/gallery/19.jpg";
import gallery20 from "../assets/gallery/20.jpg";
import gallery21 from "../assets/gallery/21.jpg";
import gallery22 from "../assets/gallery/22.jpg";
import gallery23 from "../assets/gallery/23.jpg";
import gallery24 from "../assets/gallery/24.jpg";
import gallery25 from "../assets/gallery/25.jpg";
import gallery26 from "../assets/gallery/26.jpg";
import gallery27 from "../assets/gallery/27.jpg";
import gallery28 from "../assets/gallery/28.jpg";
import gallery29 from "../assets/gallery/29.jpg";
import gallery30 from "../assets/gallery/30.jpg";
import gallery31 from "../assets/gallery/31.jpg";
import gallery32 from "../assets/gallery/32.jpg";
import gallery33 from "../assets/gallery/33.jpg";
import gallery34 from "../assets/gallery/34.jpg";
import gallery35 from "../assets/gallery/35.jpg";
import gallery36 from "../assets/gallery/36.jpg";
import gallery37 from "../assets/gallery/37.jpg";
import gallery38 from "../assets/gallery/38.jpg";
import gallery39 from "../assets/gallery/39.jpg";
import gallery40 from "../assets/gallery/40.jpg";
import gallery41 from "../assets/gallery/41.jpg";
import gallery42 from "../assets/gallery/42.jpg";
import gallery43 from "../assets/gallery/43.jpg";
import gallery44 from "../assets/gallery/44.jpg";
import gallery45 from "../assets/gallery/45.jpg";
import gallery46 from "../assets/gallery/46.jpg";
import gallery47 from "../assets/gallery/47.jpg";
import gallery48 from "../assets/gallery/48.jpg";
import gallery49 from "../assets/gallery/49.jpg";
import gallery50 from "../assets/gallery/50.jpg";
import code1 from "../assets/code/1.jpg";
import code2 from "../assets/code/2.jpg";
import code3 from "../assets/code/3.jpg";
import code4 from "../assets/code/4.jpg";
import code5 from "../assets/code/5.jpg";
import code6 from "../assets/code/6.jpg";
import code7 from "../assets/code/7.jpg";
import wedo1 from "../assets/wedo/1.jpg";
import wedo2 from "../assets/wedo/2.jpg";
import wedo3 from "../assets/wedo/3.jpg";
import wedo4 from "../assets/wedo/4.jpg";
import galleryNew1 from "../assets/gallery2024/image (1).jpg";
import galleryNew2 from "../assets/gallery2024/image (2).jpg";
import galleryNew3 from "../assets/gallery2024/image (3).jpg";
import galleryNew4 from "../assets/gallery2024/image (4).jpg";
import galleryNew5 from "../assets/gallery2024/image (5).jpg";
import galleryNew6 from "../assets/gallery2024/image (6).jpg";
import galleryNew7 from "../assets/gallery2024/image (7).jpg";
import galleryNew8 from "../assets/gallery2024/image (8).jpg";
import galleryNew9 from "../assets/gallery2024/image (9).jpg";
import galleryNew10 from "../assets/gallery2024/image (10).jpg";
import galleryNew11 from "../assets/gallery2024/image (11).jpg";
import galleryNew12 from "../assets/gallery2024/image (12).jpg";
import galleryNew13 from "../assets/gallery2024/image (13).jpg";
import galleryNew14 from "../assets/gallery2024/image (14).jpg";
import galleryNew15 from "../assets/gallery2024/image (15).jpg";
import galleryNew16 from "../assets/gallery2024/image (16).jpg";
import galleryNew17 from "../assets/gallery2024/image (17).jpg";
import galleryNew18 from "../assets/gallery2024/image (18).jpg";
import galleryNew19 from "../assets/gallery2024/image (19).jpg";
import galleryNew20 from "../assets/gallery2024/image (20).jpg";
import galleryNew21 from "../assets/gallery2024/image (21).jpg";
import galleryNew22 from "../assets/gallery2024/image (22).jpg";
import galleryNew23 from "../assets/gallery2024/image (23).jpg";
import galleryNew24 from "../assets/gallery2024/image (24).jpg";
import galleryNew25 from "../assets/gallery2024/image (25).jpg";
import galleryNew26 from "../assets/gallery2024/image (26).jpg";
import galleryNew27 from "../assets/gallery2024/image (27).jpg";
import galleryNew28 from "../assets/gallery2024/image (28).jpg";
import galleryNew29 from "../assets/gallery2024/image (29).jpg";
import galleryNew30 from "../assets/gallery2024/image (30).jpg";
import galleryNew31 from "../assets/gallery2024/image (31).jpg";
import galleryNew32 from "../assets/gallery2024/image (32).jpg";
import galleryNew33 from "../assets/gallery2024/image (33).jpg";
import galleryNew34 from "../assets/gallery2024/image (34).jpg";
import galleryNew35 from "../assets/gallery2024/image (35).jpg";
import galleryNew36 from "../assets/gallery2024/image (36).jpg";
import galleryNew37 from "../assets/gallery2024/image (37).jpg";
import galleryNew38 from "../assets/gallery2024/image (38).jpg";
import galleryNew39 from "../assets/gallery2024/image (39).jpg";
import galleryNew40 from "../assets/gallery2024/image (40).jpg";
import galleryNew41 from "../assets/gallery2024/image (41).jpg";
import galleryNew42 from "../assets/gallery2024/image (42).jpg";
import galleryNew43 from "../assets/gallery2024/image (43).jpg";
import galleryNew44 from "../assets/gallery2024/image (44).jpg";
import galleryNew45 from "../assets/gallery2024/image (45).jpg";
import galleryNew46 from "../assets/gallery2024/image (46).jpg";
import galleryNew47 from "../assets/gallery2024/image (47).jpg";
import galleryNew48 from "../assets/gallery2024/image (48).jpg";
import galleryNew49 from "../assets/gallery2024/image (49).jpg";
import galleryNew50 from "../assets/gallery2024/image (50).jpg";
import galleryNew51 from "../assets/gallery2024/image (51).jpg";
import galleryNew52 from "../assets/gallery2024/image (52).jpg";
import galleryNew53 from "../assets/gallery2024/image (53).jpg";
import galleryNew54 from "../assets/gallery2024/image (54).jpg";
import galleryNew55 from "../assets/gallery2024/image (55).jpg";
import galleryNew56 from "../assets/gallery2024/image (56).jpg";
import galleryNew57 from "../assets/gallery2024/image (57).jpg";
import galleryNew58 from "../assets/gallery2024/image (58).jpg";
import galleryNew59 from "../assets/gallery2024/image (59).jpg";

export const Gallery = {
  code1,
  code2,
  code3,
  code4,
  code5,
  code6,
  code7,
  wedo1,
  wedo2,
  wedo3,
  wedo4,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  gallery16,
  gallery17,
  gallery18,
  gallery19,
  gallery20,
  gallery21,
  gallery22,
  gallery23,
  gallery24,
  gallery25,
  gallery26,
  gallery27,
  gallery28,
  gallery29,
  gallery30,
  gallery31,
  gallery32,
  gallery33,
  gallery34,
  gallery35,
  gallery36,
  gallery37,
  gallery38,
  gallery39,
  gallery40,
  gallery41,
  gallery42,
  gallery43,
  gallery44,
  gallery45,
  gallery46,
  gallery47,
  gallery48,
  gallery49,
  gallery50,
};
export const GalleryArray = [
  // code1,
  // code2,
  // code3,
  // code4,
  // code5,
  // code6,
  // code7,
  // wedo1,
  // wedo2,
  // wedo3,
  // wedo4,
  // gallery1,
  // gallery2,
  // gallery3,
  // gallery4,
  // gallery5,
  // gallery6,
  // gallery7,
  // gallery8,
  // gallery9,
  // gallery10,
  // gallery11,
  // gallery12,
  // gallery13,
  // gallery14,
  // gallery15,
  // gallery16,
  // gallery17,
  // gallery18,
  // gallery19,
  // gallery20,
  // gallery21,
  // gallery22,
  // gallery23,
  // gallery24,
  // gallery25,
  // gallery26,
  // gallery27,
  // gallery28,
  // gallery29,
  // gallery30,
  // gallery31,
  // gallery32,
  // gallery33,
  // gallery34,
  // gallery35,
  // gallery36,
  // gallery37,
  // gallery38,
  // gallery39,
  // gallery40,
  // gallery41,
  // gallery42,
  // gallery43,
  // gallery44,
  // gallery45,
  // gallery46,
  // gallery47,
  // gallery48,
  // gallery49,
  // gallery50,
  galleryNew1,
  galleryNew2,
  galleryNew3,
  galleryNew4,
  galleryNew5,
  galleryNew6,
  galleryNew7,
  galleryNew8,
  galleryNew9,
  galleryNew10,
  galleryNew11,
  galleryNew12,
  galleryNew13,
  galleryNew14,
  galleryNew15,
  galleryNew16,
  galleryNew17,
  galleryNew18,
  galleryNew19,
  galleryNew20,
  galleryNew21,
  galleryNew22,
  galleryNew23,
  galleryNew24,
  galleryNew25,
  galleryNew26,
  galleryNew27,
  galleryNew28,
  galleryNew29,
  galleryNew30,
  galleryNew31,
  galleryNew32,
  galleryNew33,
  galleryNew34,
  galleryNew35,
  galleryNew36,
  galleryNew37,
  galleryNew38,
  galleryNew39,
  galleryNew40,
  galleryNew41,
  galleryNew42,
  galleryNew43,
  galleryNew44,
  galleryNew45,
  galleryNew46,
  galleryNew47,
  galleryNew48,
  galleryNew49,
  galleryNew50,
  galleryNew51,
  galleryNew52,
  galleryNew53,
  galleryNew54,
  galleryNew55,
  galleryNew56,
  galleryNew57,
  galleryNew58,
  galleryNew59,
];
