import React, { useEffect } from "react";
import AboutTemplate from "../../Templetes/About/About.template";

const AboutPage = () => {
  useEffect(() => {
    document.title = "International Robot Challenge - About";
  }, []);
  return <AboutTemplate />;
};

export default AboutPage;
