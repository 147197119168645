import React, { useEffect } from "react";
import SumoTemplate from "../../../Templetes/Categories/Details/Sumo/Sumo.template";

const SumoPage = () => {
  useEffect(() => {
    document.title = "Internatioal Robot Challenge - RoboSumo";
  }, []);
  return <SumoTemplate />;
};

export default SumoPage;
