import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../helpers/Images";
import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "primary.darker",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: { xs: "20px 20px", sm: "20px 60px", lg: "20px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", color: "white" }}>
            <img
              style={{ height: "60px", cursor: "pointer" }}
              src={Images.LogoWithoutText}
              alt="International Robot Challenge Championship Logo"
              onClick={() => navigate("/")}
            />
            <Box
              sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center", color: "white" }}
            >
              <Typography
                variant={"h6"}
                sx={{
                  textAlign: "center",
                  fontWeight: "200",
                  color: "secondary.main",
                  fontSize: "15px",
                  margin: "0 5px",
                  cursor: "pointer",
                  textDecoration: "underline"
                }}
                onClick={() => navigate("/privacy")}
              >
                Privacy
              </Typography>
              <Typography
                variant={"h6"}
                sx={{
                  textAlign: "center",
                  fontWeight: "200",
                  color: "secondary.main",
                  fontSize: "15px",
                  margin: "0 5px",
                  cursor: "pointer",
                  textDecoration: "underline"
                }}
                onClick={() => navigate("/terms")}
              >
                Terms
              </Typography>
              <Typography
                variant={"h6"}
                sx={{
                  textAlign: "center",
                  fontWeight: "200",
                  color: "secondary.main",
                  fontSize: "15px",
                  margin: "0 5px",
                  cursor: "pointer",
                  textDecoration: "underline"
                }}
                onClick={() => navigate("/refund")}
              >
                Refund
              </Typography>
            </Box>
          </Box>

          <div
            style={{ display: "flex", alignItems: "center", height: "60px" }}
          >
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "center",
                fontWeight: "200",
                color: "secondary.main",
                fontSize: "15px",
                marginRight: "5px",
              }}
            >
              Stay in touch
            </Typography>
            <a
              href="https://www.facebook.com/InternationalRobotChallenge"
              target="_blank"
            >
              <img
                alt="facebook"
                className="SocialNavIcon"
                style={{
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  marginTop: "5px",
                }}
                src={Images.FacebookIconCircle}
              />
            </a>
            <a target="_blank" href="https://wa.me/+201022758877">
              <img
                alt="whatsapp"
                className="SocialNavIcon"
                style={{
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  marginTop: "5px",
                }}
                src={Images.WhatsAppIconCircle}
              />
            </a>
            <a
              href="https://www.instagram.com/internationalrobotchallenge?igsh=MXJvN2QxazlweWhvdA=="
              target="_blank"
            >
              <img
                alt="instagram"
                className="SocialNavIcon"
                style={{
                  filter:
                    "invert(100%) sepia(8%) saturate(243%) hue-rotate(147deg) brightness(113%) contrast(100%)",
                  marginTop: "5px",
                }}
                src={Images.InstagramIconCircle}
              />
            </a>
          </div>
        </Box>
        <Box
          sx={{ display: { xs: "flex", sm: "none" }, alignItems: "center", color: "white", justifyContent: "Center", marginTop: "10px", marginBottom: "-15px" }}
        >
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
              margin: "0 5px",
              cursor: "pointer",
              textDecoration: "underline"
            }}
            onClick={() => navigate("/privacy")}
          >
            Privacy
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
              margin: "0 5px",
              cursor: "pointer",
              textDecoration: "underline"
            }}
            onClick={() => navigate("/terms")}
          >
            Terms
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
              margin: "0 5px",
              cursor: "pointer",
              textDecoration: "underline"
            }}
            onClick={() => navigate("/refund")}
          >
            Refund
          </Typography>
        </Box>
        <Box sx={{
          borderTop: "1px solid #fff", marginTop: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "5px",
          flexDirection: { xs: "column", md: "row" }
        }}>
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
            }}
          >
            InternationalRobotChallenge© 2024 All Rights reserved.
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              textAlign: "center",
              fontWeight: "200",
              color: "secondary.main",
              fontSize: "15px",
              display: "flex",
              alignItems: "center"
            }}
          >
            Powered by <img src={Images.pulsonic} alt="pulsonic" style={{ height: "20px", marginLeft: "10px" }} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
