import React, { useEffect } from "react";
import BowlingTemplate from "../../../Templetes/Categories/Details/Bowling/Bowling.template";

const BowlingPage = () => {
  useEffect(() => {
    document.title = "Internatioal Robot Challenge - Bowling";
  }, []);
  return <BowlingTemplate />;
};

export default BowlingPage;
