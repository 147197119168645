import React, { useEffect } from "react";
import LineFollowerTemplate from "../../../Templetes/Categories/Details/LineFollower/LineFollower.template";

const LineFollowerPage = () => {
  useEffect(() => {
    document.title = "Internatioal Robot Challenge - Line Follower";
  }, []);
  return <LineFollowerTemplate />;
};

export default LineFollowerPage;
