import React, { useEffect } from "react";
import CategoriesTemplate from "../../Templetes/Categories/Categories.template";

const CategoriesPage = () => {
  useEffect(() => {
    document.title = "International Robot Challenge - Categories";
  }, []);
  return <CategoriesTemplate />;
};

export default CategoriesPage;
