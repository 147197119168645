import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";
import "./Competitions.css";
import { Images } from "../../../../helpers/Images";

const CompetitionsSection = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: {
          xs: "50px 20px 20px 20px",
          sm: "50px 60px",
          lg: "70px 120px",
        },
        backgroundColor: "secondary.main",
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "500",
          color: "primary.darker",
          marginBottom: "5px",
        }}
      >
        IRC Community
      </Typography>
      <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.main",
          margin: "auto",
        }}
      />
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "space-around",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <img
            src={Images.codeLogo}
            style={{ maxHeight: "300px", width: "fit-content", margin: "auto" }}
          />
          <Typography
            variant={"p"}
            sx={{
              textAlign: "center",
              fontSize: "17px",
              fontWeight: "500",
              color: "black",
              maxWidth: { xs: "100%", sm: "80%" },
              margin: "auto",
            }}
          >
            Code Challenge is a exceptional community and global competition
            where students aged 5 to 24 can demonstrate their coding skills and
            unlock their full potential.
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <a href="https://codechallenge-championship.org/" target="_blank">
              <Button
                sx={{
                  fontSize: "15px",
                  margin: "10px 0 40px 0",
                  fontWeight: "600",
                  color: "secondary.main",
                  backgroundColor: "primary.main",
                }}
                variant="contained"
                color="secondary"
              >
                Visit Code Challenge
              </Button>
            </a>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <img
            src={Images.wedoLogo}
            style={{ maxHeight: "300px", width: "fit-content", margin: "auto" }}
          />
          <Typography
            variant={"p"}
            sx={{
              textAlign: "center",
              fontSize: "17px",
              fontWeight: "500",
              color: "black",
              maxWidth: { xs: "100%", sm: "80%" },
              margin: "auto",
            }}
          >
            We Do Challenge is a unique global competition that ignites a
            passion for STEAM in young minds aged from 4 to 10 years through
            real-world problem-solving.
          </Typography>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <a href="https://wedochallenge.org/" target="_blank">
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  fontSize: "15px",
                  margin: "10px 0 40px 0",
                  fontWeight: "600",
                  color: "secondary.main",
                  backgroundColor: "primary.main",
                }}
              >
                Visit We Do Challenge
              </Button>
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompetitionsSection;
