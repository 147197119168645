import React, { useEffect } from "react";
import FreeStyleTemplate from "../../../Templetes/Categories/Details/FreeStyle/FreeStyle.template";

const FreeStylePage = () => {
  useEffect(() => {
    document.title = "Internatioal Robot Challenge - Free Style";
  }, []);
  return <FreeStyleTemplate />;
};

export default FreeStylePage;
