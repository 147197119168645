import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { paymentURL } from "../../Hooks/URL";
import PaymentResultTemplate from "../../Templetes/PaymentResult/PaymentResult.template";
import useFetch from "../../Hooks/useFetch";
import { toast } from "react-toastify";

const PaymentResultPage = () => {
  const navigate = useNavigate();
  console.log(window.location.search);
  const [Data, setData] = useState({});

  const { data, loading, error } = useFetch(
    `${paymentURL}/callback${window.location.search}`
  );

  useEffect(() => {
    if (!loading && !error && data) {
      setData(data?.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (Data?.success && Data?.success != "true") {
      toast.error(
        "Your payment is not successfully processed, please try again",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  }, [Data]);

  useEffect(() => {
    document.title = "International Robot Challenge - Payment Result";
  }, []);

  console.log(Data);

  return (
    <PaymentResultTemplate Data={Data} loading={loading} navigate={navigate} />
  );
};

export default PaymentResultPage;
