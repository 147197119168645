import { AcademiesLogo, Images } from "./Images";

export const categoriesData = [
  {
    image: Images.sumo1,
    title: "RoboSumo",
    link: "/competition/sumo",
  },
  {
    image: Images.follow1,
    title: "Line Follower",
    link: "/competition/line-follower",
  },
  {
    image: Images.puck,
    title: "Puck Collect",
    link: "/competition/puck-collect",
  },
  {
    image: Images.freeStyle,
    title: "FreeStyle",
    link: "/competition/free-style",
  },
  {
    image: Images.bowling,
    title: "Robot Bowling",
    link: "/competition/bowling",
  },
];

export const reviews = [
  {
    review: (
      <span>
        The best event ever <br /> My favorite event ♥️
      </span>
    ),
    name: "Zyad",
    date: "15 - 10 - 2022",
  },
  {
    review: (
      <span>
        The best competition for programming ever with many challenges 🔥😍
      </span>
    ),
    name: "Ahmed",
    date: "17 - 10 - 2022",
  },
  {
    review: (
      <span>
        من اجمل المسابقات ال سجلنا فيها بجد كانت ممتعه واسعدت كل الاطفال
      </span>
    ),
    name: "Abd allah",
    date: "24 - 10 - 2022",
  },
];

export const arrayOfAcademies = [
  { name: "4My-Robot" },
  { name: "AA Robotics" },
  { name: "AAST teams" },
  { name: "ACA Robotics" },
  { name: "AI Academy" },
  { name: "Al Farouk Academy" },
  { name: "Al Safa Language School" },
  { name: "Almobbtakron" },
  { name: "Alpha Robot Academy" },
  { name: "American languages schools Shebin Elkoum" },
  { name: "Artifinity" },
  { name: "ASME Assuit" },
  { name: "Asdaa" },
  { name: "BareeQ Academy" },
  { name: "begining Academy" },
  { name: "Big Hero Academy" },
  { name: "Bricks Builder" },
  { name: "Child Home Academy" },
  { name: "CodeCraft" },
  { name: "CodeWave" },
  { name: "Creative Generation Academy" },
  { name: "Discovery Academy (Monofia)" },
  { name: "Discovery Academy (TAGAMOU3)" },
  { name: "ETS Academy" },
  { name: "Future Makers Academy" },
  { name: "FutureMinds Academy" },
  { name: "Futuristic Science Organization" },
  { name: "GENIUS CODER" },
  { name: "Genius Academy" },
  { name: "Genius Minds" },
  { name: "HB Tech Academy" },
  { name: "Helwan University Faculty of Engineering" },
  { name: "I Can Code Academy" },
  { name: "I.C academy" },
  { name: "Ibdaa Academy" },
  { name: "Innova STEM Education" },
  { name: "Innovation-hub" },
  { name: "IRON Teams" },
  { name: "IVY STEM International School" },
  { name: "Jupiter Academy" },
  { name: "Little Engineer Academy" },
  { name: "Little Engineer Center" },
  { name: "M&P Robotics" },
  { name: "Maven International School" },
  { name: "Mawaheb (El-Aasher)" },
  { name: "Mawaheb Academy (Maadi)" },
  { name: "Mawaheb Academy (Nasr city)" },
  { name: "Mawaheb Academy (Suez)" },
  { name: "MAY UNIVERSITY IN CAIRO" },
  { name: "Mega Robotics Academy" },
  { name: "Metanoia planet Academy" },
  { name: "MMS Academy" },
  { name: "Map Language School" },
  { name: "Mind Shine" },
  { name: "Noor Language School" },
  { name: "Robot Academy" },
  { name: "ROBOTIC-FOR-KIDS" },
  { name: "RoboDizer Academy" },
  { name: "Roboharvest Academy" },
  { name: "Robokids Academy" },
  { name: "Robotica Academy" },
  { name: "Robotix Academy" },
  { name: "Robotopia Academy (Fayoum)" },
  { name: "STS Academy" },
  { name: "STEM Zone Academy" },
  { name: "Sciences Academy" },
  { name: "Techware Academy" },
  { name: "Techno Square Academy" },
  { name: "Techno Titans Academy" },
  { name: "Technospace team" },
  { name: "Technology leaders (6 October)" },
  { name: "Technology leaders (Zayed)" },
  { name: "Treasures Academy Ganakles" },
  { name: "Treasures Academy sidi gaber" },
  { name: "Utopia Academy" },
  { name: "VBO TECH" },
  { name: "Willy Robotics Academy" },
  { name: "Wissen Academy" },
  { name: "X_robot Academy" },
  { name: "ZHUB Academy" },
];

export const academiesData = [
  { image: AcademiesLogo.academy1 },
  { image: AcademiesLogo.academy2 },
  { image: AcademiesLogo.academy3 },
  { image: AcademiesLogo.academy4 },
  { image: AcademiesLogo.academy5 },
  { image: AcademiesLogo.academy6 },
  { image: AcademiesLogo.academy7 },
  { image: AcademiesLogo.academy8 },
  { image: AcademiesLogo.academy9 },
  { image: AcademiesLogo.academy10 },
  { image: AcademiesLogo.academy11 },
  { image: AcademiesLogo.academy12 },
  { image: AcademiesLogo.academy13 },
  { image: AcademiesLogo.academy14 },
  { image: AcademiesLogo.academy15 },
  { image: AcademiesLogo.academy16 },
  { image: AcademiesLogo.academy17 },
  { image: AcademiesLogo.academy18 },
  { image: AcademiesLogo.academy19 },
  { image: AcademiesLogo.academy20 },
  { image: AcademiesLogo.academy21 },
  { image: AcademiesLogo.academy22 },
  { image: AcademiesLogo.academy23 },
  { image: AcademiesLogo.academy24 },
  { image: AcademiesLogo.academy25 },
  { image: AcademiesLogo.academy26 },
  { image: AcademiesLogo.academy27 },
  { image: AcademiesLogo.academy28 },
  { image: AcademiesLogo.academy29 },
  { image: AcademiesLogo.academy30 },
  { image: AcademiesLogo.academy31 },
  { image: AcademiesLogo.academy32 },
  { image: AcademiesLogo.academy33 },
  { image: AcademiesLogo.academy34 },
  { image: AcademiesLogo.academy35 },
  { image: AcademiesLogo.academy36 },
  { image: AcademiesLogo.academy37 },
  { image: AcademiesLogo.academy38 },
  { image: AcademiesLogo.academy39 },
  { image: AcademiesLogo.academy40 },
  { image: AcademiesLogo.academy41 },
  { image: AcademiesLogo.academy42 },
];

export const Q_Follow = [
  {
    question: "What are the dimensions of the playground?",
    answer: "200*440 cm",
  },
  {
    question: "What is the type of playground material?",
    answer: "The playground material will be from banner.",
  },
  {
    question: "How can we calculate time?",
    answer:
      "-Time is recorded from crossing the light gate at the beginning of the line to cross the light gate again at the end of the line. The judge may use a stopwatch to record the time if the light gate is not working or damaged.",
  },
  {
    question:
      "Will there be an explanation of the design and programming of the robot?",
    answer:
      "Yes, to ensure that the team implemented the robot itself and understands all its parts, and also to determine the winners of the special prizes.",
  },
  {
    question:
      "Will there be a calibration before each round?  And how long is it?",
    answer:
      "Yes, there will be a calibration before the start of the rounds from five to ten minutes according to the competition schedule, and there will be one minute for calibration directly before the start of each round.",
  },
  {
    question: "Can Spike prime be used in Lego category?",
    answer:
      "Yes, you can use LEGO Mindstorm kits based on RCX, NXT, EV3 or Spike Prime).",
  },
  {
    question:
      "If the robot exits from a point and returns to it again, will it continue the round?",
    answer:
      "The robot that gets out of the line and returns back to the line at the same point of exit or before it can continue the round unless it didn’t reach the light gate. The robot that gets out of the arena surface will lose the round even if it returns back again to the line. The robot that gets out of the line and returns back to the line at farther point of exit point will lose the round.",
  },
  {
    question: "Will the line have gabs, intersections or barriers?",
    answer: "No, there will be no gabs, intersections or barriers.",
  },
];

export const Q_LegoSumo = [
  {
    question: "What are the dimensions of the robot?",
    answer:
      "The dimensions of the robot are 15x15 cm in length and width, and the height is open. It weighs one kilogram.",
  },
  {
    question: "Can the robot expand during the match?",
    answer:
      "The robot can expand during the match, but only after 5 seconds from the start, and preferably only one piece.",
  },
  {
    question: "What happens if the robot moves before the five seconds?",
    answer:
      "An alert will be given, and after three alerts, the match will be lost.",
  },
  {
    question: "What happens if a piece falls off the robot during the match?",
    answer:
      "The match will continue, and if the team wins, the piece will be taken and weighed. If it weighs more than 5 grams, the round will be lost.",
  },
  {
    question: "What are the dimensions of the field?",
    answer:
      "The field is a circle with a diameter of 77 cm. A vinyl banner is placed on it, with a black matte finish and white borders. The diameter of the black circle is 72 cm, and the white borders are 2.5 cm on the edges of the circle.",
  },
  {
    question: "When is a winner declared?",
    answer:
      "The winner is declared if they win 2 rounds out of the 3 or if they win one round and tie 2 rounds.",
  },
  {
    question: "How long is the match?",
    answer: "The entire match is 3 minutes, divided into 1 minute per round.",
  },
  {
    question: "When is a round replayed?",
    answer:
      "If both robots fall at the same time and the referee cannot determine who fell first, or if someone enters the field area during the round and causes the robot to fall",
  },
];

export const Q_MegaSumo = [
  {
    question:
      "What is the allowed age to participate in the Mega Sumo category?",
    answer:
      "You can participate in the Mega Sumo category if you are between 9 and 24 years old, divided into three age categories junior, senior and adult.",
  },
  {
    question:
      "What materials can I use to build a robot for the Mega Sumo category, and is there a limit to the number of motors or sensors?",
    answer:
      "In the Mega Sumo category, you can build the robot using any materials and components, such as plastic, wood, or metal, and you can use any number of motors and sensors",
  },
  {
    question:
      "What is the maximum size of the robot in the Mega Sumo category?",
    answer:
      "In the Mega Sumo category, the maximum dimensions of the robot are 20 centimeters in length and 20 centimeters in width, and there is no maximum limit for the height. The robot can expand beyond these dimensions to any other dimensions after the start of the match and after the first 5 seconds. However, the maximum weight of the robot is 3 kilograms",
  },
  {
    question:
      "Can I increase the adhesion of the robot to the ground using adhesive materials, for example?",
    answer:
      "In the Mega Sumo category you cannot increase the adhesion of the robot to the ground using adhesive materials, and this will be measured by placing an A4 paper on the ground, placing the robot on top of it, and then lifting the robot off the ground. The paper should not remain stuck to the robot for more than two seconds. However, you can use magnets or vacuum pumps.",
  },
  {
    question:
      "Can I interfere with the opponent robot's sensors so that it cannot see me?",
    answer:
      "In the Mega Sumo category, no, you cannot interfere with the opponent robot's sensors, such as by using devices that emit infrared or ultrasonic waves to interfere with them.",
  },
  {
    question:
      "What is the shape, color, and dimensions of the competition playground?",
    answer:
      "The Megs Sumo competition playground is a circle with an outer diameter of 154 centimeters. The top surface is made of steel and painted with a layer of paint, which is a black circle with a diameter of 144 centimeters surrounded by a white border with a width of 5 centimeters. All of these dimensions are subject to a tolerance of 5%",
  },
  {
    question: "How is a Mega Sumo match played?",
    answer:
      "A Mega Sumo match consists of three rounds, each round lasts for a maximum of one minute. If one team scores two Yukohos points before the third round, the third round will not be played.",
  },
  {
    question:
      "How are the winners determined and what happens in case of a tie?",
    answer:
      "The teams are divided into groups and the number of qualifying teams from each group is determined based on the number of teams. A league is played between the teams and the points earned by each team are accumulated. If two teams have the same number of points, the winning team is chosen when these two teams faced each other.",
  },
  {
    question:
      "What happens if the robot moves during the first 5 seconds of the round?",
    answer:
      "If the robot moves before the 5 seconds are up, the team will receive a warning and the round will be stopped and repeated. If the team receives three warnings during the match, they will lose the entire match.",
  },
  {
    question:
      "Will the team be tested or questioned on the design and programming of the robot?",
    answer:
      "Yes, the team will be tested to ensure that the team members are the ones who designed and programmed the robot themselves, and also to determine the special prizes.",
  },
  {
    question: "How will the dimensions of the robot be measured?",
    answer:
      "The dimensions of the robot will be measured using an open-topped box, and the weight will be measured using a scale. The dimensions and weight will be measured before each match.",
  },
];

export const Q_Bowling = [
  {
    question: "What is the allowed type of robots in Bowling Category?",
    answer:
      "This category divides into three sections (Junior, Senior and Adults) all of them open robots (using any type of robot)",
  },
  {
    question: "What is the allowed type of controlling?",
    answer: "The robot must be autonomous.",
  },
  {
    question: "What are the Maximum dimensions of the Robot?",
    answer:
      "The dimension of the robot are ( 20 cm L x 20 cm w ) maximum , with no restriction for the height, the robot components are allowed to stretch, but the maximum size of the robot after stretching should not exceed 30 cm L*30 cm W, with no restriction  for the height.",
  },
  {
    question: "What are the definitions of Shot, Frame and Round?",
    answer: (
      <p>
        Shot: is a try to knock down the pins that the robot leaves the base
        area and serve the ball without touching any line of the launching area
        then it backs to the base area.
        <br />
        Frame: consists of 2 shots (except the last frame of each round)
        <br />
        Round: consists of 5 Frames
      </p>
    ),
  },
  {
    question: "How does the judge calculate the time of each round?",
    answer:
      "The total time of each round is the time consumed to finish the 5 frames. However, the maximum time of a round is 3 minutes. After that the round will be stopped by the Judge.",
  },
  {
    question: "Does the timer be paused?",
    answer:
      "Yes. The timer is paused between each two frames to allow the judge to rearrange the bowling pins.",
  },
  {
    question: "What is the score points of each Bowling pin?",
    answer: "Each pin has a score of 1 point.",
  },
  {
    question:
      "Will the judge calculate the score of the faulty knocked down pins ?",
    answer:
      "No. the score of knocked down pins by fault (rebounded hits, serving while exceed any line of launch area,etc) will not be neither calculated nor rearranged.",
  },
  {
    question: "What are Strike and Spare?",
    answer: (
      <p>
        Strike is declared when the robot knock down all of 10 pins using 1st
        shot of a frame <br />
        Spare is declared when the robot knock down all of 10 pins using both
        1st and 2nd shots of a frame
      </p>
    ),
  },
  {
    question: "What happen if there is a tie between two teams?",
    answer:
      "If the final score of two teams are the same, the faster team to achieve this score wins.",
  },
];

export const Q_Puck = [
  {
    question: "What is The aim of this competition?",
    answer:
      "The aim of this competition is to collect all pucks of the assigned colour and place them into the assigned home base. ",
  },
  {
    question: "What are the Field Dimensions?",
    answer:
      "The Field in this competition has a size of 250 x 250 cm. It is framed with a board of at least 8 cm height of any color, and there are Two Home Bases (70 cm x 70 cm) (red and blue) are positioned.",
  },
  {
    question: "How many pucks and what are their dimensions?",
    answer:
      "Ten pucks of each colour with size of (4 cm diameter, 2 cm height).",
  },
  {
    question: "What is The aim of this competition?",
    answer:
      "The aim of this competition is to collect all pucks of the assigned colour and place them into the assigned home base. ",
  },
  {
    question: "What is the total time for each round?",
    answer: "The total time for each round is 3 minutes. ",
  },
  {
    question: "What is the Robot Dimensions?",
    answer:
      "The dimension of the robot is (50 cm Length x 50 cm width) maximum, with no restriction for the height.",
  },
  {
    question: "When does the game end?",
    answer: (
      <p>
        The match ends when any of the following cases occur:
        <br />• all pucks have been collected. <br />• after a duration of 3
        minutes.
        <br />• when the judge decides so. <br />• when any player touches the
        robot.
      </p>
    ),
  },
  {
    question: "When is the puck counted as collected?",
    answer: (
      <p>
        • Its entire body is located unmoved within any of the home bases for
        more than 1 second.
        <br />
        • It is touching the floor. <br />• It is outside the convex shape of
        the robot and not covered by any part of a robot.
      </p>
    ),
  },
  {
    question: "How is the score calculated?",
    answer: (
      <p>
        • If the colour of the puck is same as the colour of the home base, the
        score will be increased by 1. <br />• If the colour of the puck is
        different from the colour of the home base, the score will be decreased
        by 1. <br />
        <br />
        The total score of a robot at the end of a match must not be negative.
        Therefore, if there are more wrong-coloured pucks than right coloured
        pucks the score will be corrected to 0.
      </p>
    ),
  },
];

export const Q_FreeStyle = [
  {
    question: "What are the dimensions of the poster used in the competition?",
    answer:
      "The maximum width for the poster is 122 cm and the maximum height is 274 cm.",
  },
  {
    question: "How will be the evaluation of the poster?",
    answer:
      "The evaluation of the poster depends on Main points such as Containing the main points of the project (Problem, Solution, Background, Materials and Results) and the organization of the poster too.",
  },
  {
    question: "Can I try my project while the judging Rounds?",
    answer: "Yes you can try your project.",
  },
  {
    question: "Is there is any restrictions or dimensions for the prototype?",
    answer: "No there is no restrictions for the prototype..",
  },
  {
    question: "Is there is specific type of robot in this category?",
    answer: "No, you can use any type of robot.",
  },
  {
    question: "Is there is a specific programming language that I have to use?",
    answer: "No, you can use any programming language.",
  },
  {
    question:
      "Should I send any information about the project before the competition day?",
    answer:
      "Yes, You have to send an Abstract (1 Paper maximum) before the competition day",
  },
  {
    question: "Who will be qualified for the international competition?",
    answer:
      "The first three teams in (Junior , Senior and Adult) will be qualified to participate in the international event in China.",
  },
  {
    question: "Should the team prepare A Report book for the project?",
    answer: "Yes, Each team should bring Report book in the competition day",
  },
];
