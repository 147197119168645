import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Images } from "../../helpers/Images";
import { useLocation } from "react-router-dom";
import axios from "axios";

const RefundTemplate = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "35px", sm: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          International Robot Challenge's Refund & Cancellation Policy
        </Typography>
        <Typography
          sx={{
            textAlign: "start",
            fontSize: "20px",
            fontWeight: "400",
            color: "primary.darker",
          }}
        >
          Last Updated: May, 2024
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "150px" },
            width: "100%",
            backgroundColor: "primary.main",
            marginTop: "5px",
            marginBottom: "50px"
          }}
        />
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Eligibility for Refunds:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          In general, application fees for the International Robot Challenge are non-refundable due to the multi-stage registration process. Once a user reaches the payment stage and successfully completes the transaction, the payment cannot be reversed.
          <br /><br />
          However, we understand that technical errors can occur. If a transaction error takes place where the payment is processed on your end but not reflected on our website, please contact us immediately.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Process for Refunds:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          Should you encounter an issue with your transaction, you can request a refund by contacting our dedicated customer service team. You can reach them by email at Internationalrobotchallenge1@gmail.com or by phone at +201022758877.
          <br /><br />
          Important: When contacting us, please ensure you include your order number and email address in your request. This will allow us to expedite the investigation process. We take all reported transaction issues seriously and will thoroughly investigate the matter. You can expect a response from our team within 48 business hours.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Cancellation Policy:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          Due to the irreversible nature of the application process following successful payment, cancellations are not possible.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Non-Refundable Items:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          The application registration fee is non-refundable, except in the limited case of a transaction error as outlined above.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Additional Information:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          This policy is available in English. <br />
          These policies are subject to change. We will update this page if our policies change.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "23px", sm: "28px" },
            fontWeight: "400",
            color: "primary.darker",
            display: "block",
            marginTop: "50px",
            marginBottom: "20px"
          }}
        >
          Contact Us:
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "15px", sm: "18px" },
            fontWeight: "200",
            color: "primary.darker",
            display: "block",
            marginBottom: "20px"
          }}
        >
          If you have any questions regarding this Privacy Policy, please do not hesitate to contact us at: Internationalrobotchallenge1@gmail.com
        </Typography>
      </Box>
    </>
  );
};

export default RefundTemplate;
