import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { categoriesData } from "../../helpers/Arrays";

const CategoriesTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "55px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Internatioal Robot Challenge 2023
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "350px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            maxWidth: "750px",
            margin: "40px auto 10px auto",
            fontSize: "17px",
          }}
        >
          Each year Internatioal Robot Challenge, one of the world’s largest artificial
          intelligent robotic championships, brings together people from all
          over Egypt who compete with self-made, autonomous robots in various
          competitions.
        </Typography>
      </Box> */}
      <Box
        id={"categories"}
        sx={{
          width: "100%",
          maxWidth: "1500px",
          margin: "auto",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "55px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Our Categories
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Grid
          container
          spacing={2}
          sx={{ marginTop: "50px", justifyContent: "center" }}
        >
          {categoriesData?.map((item) => (
            <Grid item xs={12} md={6} xl={6}>
              <Box
                sx={{
                  backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  width: "100%",
                  minHeight: { xs: "200px", sm: "300px" },
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant={"h3"}
                  sx={{
                    textAlign: "center",
                    fontSize: "35px",
                    fontWeight: "500",
                    color: "secondary.main",
                  }}
                >
                  {item.title}
                </Typography>
                <Box
                  sx={{
                    height: "3px",
                    maxWidth: "90px",
                    width: "100%",
                    backgroundColor: "secondary.main",
                    margin: "5px auto 5px auto",
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "primary.main",
                      width: "fit-content",
                      margin: { xs: "5px 0 20px 0", sm: "10px 0 40px 0" },
                    }}
                    onClick={() => navigate(item.link)}
                  >
                    view
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default CategoriesTemplate;
