import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { academiesData } from "../../helpers/Arrays";
import LogoCarouse from "../../Components/organisms/Home/LogoCarousel/LogoCarousel";

const AboutTemplate = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "40px", sm: "55px" },
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        About Us
      </Typography>
      <Box
        sx={{
          height: "3px",
          maxWidth: { xs: "100px", md: "150px" },
          width: "100%",
          backgroundColor: "primary.main",
          margin: "auto",
        }}
      />
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "30px",
          fontWeight: "500",
          color: "primary.darker",
          marginTop: "50px",
        }}
      >
        How we are
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          color: "primary.darker",
          width: "100%",
          maxWidth: "800px",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "50px",
        }}
      >
        ​Internatioal Robot Challenge is one of the biggest competitions for self-made,
        autonomous robots in various competitions. It takes place annually. From
        the start in 2020 Internatioal Robot Challenge has grown fast, including more
        academies and more children and young people each year. The Covid
        pandemic made it very hard to organize events in 2020 and 2021. But we
        did it in 2020 we have seen the number of teams participating in
        Internatioal Robot Challenge growing more and more.
      </Typography>
      
      <LogoCarouse />
    </Box>
  );
};

export default AboutTemplate;
