import { Box, Drawer, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../../helpers/Images";
import "./NavBar.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const NavBar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const handleDrawer = (bool) => {
    setDrawerOpen(bool);
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [anchor, setAnchor] = useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchor(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchor(null);
  }

  const [openReg, setOpenReg] = useState(false);

  return (
    <Box
      sx={{
        width: "100%",
        height: "80px",
        backgroundColor: "secondary.main",
        position: "sticky",
        top: 0,
        zIndex: "1000",
        boxShadow: "0px 0px 20px 2px rgba(78,18,17,0.43)",
      }}
    >
      <Grid
        container
        alignItems={"center"}
        sx={{
          height: "100%",
          padding: { xs: "0 20px", sm: "0 60px", lg: "0 120px" },
          justifyContent: "space-between",
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Grid item xs={2} style={{ display: "flex", justifyContent: "start" }}>
          <img
            style={{ height: "60px", cursor: "pointer" }}
            src={Images.Logo}
            alt="International Robot Challenge Championship Logo"
            onClick={() => navigate("/")}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sx={{
            justifyContent: "space-around",
            alignItems: "center",
            maxWidth: "600px",
            margin: "auto",
            display: { xs: "none !important", md: "flex !important" },
          }}
        >
          <Typography className="NavLinks" onClick={() => navigate("/")}>
            Home
          </Typography>
          <Typography className="NavLinks" onClick={() => navigate("/gallery")}>
            Gallery
          </Typography>
          <Box sx={{ position: "relative" }}>
            <Typography
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={() => navigate("/categories")}
              className="NavLinks comNav"
            >
              Categories
            </Typography>
          </Box>
          <Typography
            className="NavLinks " //registration
            onClick={() => navigate("/firststage")}
          >
            Registration
            {/* <ul className="navMenu">
              <li onClick={() => navigate("/firststage")}>
                First Registration
              </li> */}
            {/* <li onClick={() => navigate("/laststage")}>Final Application</li>
              <li onClick={() => navigate("/check")}>Check Application</li> */}
            {/* </ul> */}
          </Typography>
          <Typography className="NavLinks" onClick={() => navigate("/contact")}>
            Contact
          </Typography>
          <Typography className="NavLinks" onClick={() => navigate("/about")}>
            About IRC
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: { xs: "none !important", md: "flex !important" },
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <a
            target="_blank"
            href="https://www.facebook.com/InternationalRobotChallenge"
          >
            <img
              alt="facebook"
              className="SocialNavIcon"
              src={Images.FacebookIconCircle}
            />
          </a>
          <a
            target="_blank"
            href="https://wa.me/+201022758877"
          >
            <img
              alt="whatsapp"
              className="SocialNavIcon"
              src={Images.WhatsAppIconCircle}
            />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/internationalrobotchallenge?igsh=MXJvN2QxazlweWhvdA=="
          >
            <img
              alt="instagram"
              className="SocialNavIcon"
              src={Images.InstagramIconCircle}
              onClick={() => navigate("/")}
            />
          </a>
          {/* <a
            target="_blank"
            // href="https://www.youtube.com/@RobotChallengeEgypt1"
          >
            <img
              alt="youtube"
              className="SocialNavIcon"
              src={Images.YoutubeIconCircle}
              onClick={() => navigate("/")}
            />
          </a> */}
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: { xs: "flex !important", md: "none !important" },
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <img
            alt="menu"
            className="SocialNavIcon"
            src={Images.menu}
            onClick={() => handleDrawer(true)}
          />
        </Grid>
      </Grid>
      <Drawer
        open={DrawerOpen}
        onClose={() => handleDrawer(false)}
        className="drawer"
      >
        <img
          style={{
            height: "60px",
            cursor: "pointer",
            width: "fit-content",
            margin: "20px 10px",
          }}
          src={Images.Logo}
          alt="International Robot Challenge Championship Logo"
          onClick={() => {
            navigate("/");
            handleDrawer(false);
          }}
        />
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/");
            handleDrawer(false);
          }}
        >
          Home
        </Typography>
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/gallery");
            handleDrawer(false);
          }}
        >
          Gallery
        </Typography>
        <Typography
          id="demo-positioned-button"
          className="NavLinks"
          onClick={() => {
            navigate("/categories");
            handleDrawer(false);
          }}
        >
          Categories
        </Typography>

        <Typography
          className="NavLinksReg"
          // onClick={() => {
          //   setOpenReg(!openReg);
          // }}
          onClick={() => {
            navigate("/firststage");
            handleDrawer(false);
          }}
        >
          Registration{" "}
          {/* <KeyboardArrowRightIcon
            style={{ rotate: openReg ? "90deg" : "0deg" }}
          /> */}
        </Typography>
        {openReg ? (
          <>
            {/* <Typography
              className="NavLinks InsideLink"
              onClick={() => {
                navigate("/firststage");
                handleDrawer(false);
              }}
            >
              First Registration
            </Typography> */}
            {/* <Typography
      className="NavLinks InsideLink"
      onClick={() => {
        navigate("/laststage");
        handleDrawer(false);
      }}
    >
      Final Registration
    </Typography>
    <Typography
      className="NavLinks InsideLink"
      onClick={() => {
        navigate("/check");
        handleDrawer(false);
      }}
    >
      Check Application
    </Typography> */}
          </>
        ) : (
          ""
        )}
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/contact");
            handleDrawer(false);
          }}
        >
          Contact
        </Typography>
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/about");
            handleDrawer(false);
          }}
        >
          About IRC
        </Typography>
      </Drawer>
    </Box>
  );
};

export default NavBar;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
