import React, { useEffect, useState } from "react";
import LastStageTemplate from "../../../Templetes/Registration/LastStage/LastStage.template";
import { useNavigate, useParams } from "react-router-dom";
import { applicationURL, categoriesURL } from "../../../Hooks/URL";
import { Fetch } from "../../../Hooks/Fetch";
import useFetch from "../../../Hooks/useFetch";
import useSave from "../../../Hooks/useSave";

const LastStagePage = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState("");
  const [goSave, setGoSave] = useState(false);
  const [newObject, setNewObject] = useState([]);
  const [newObjectConfirm, setNewObjectConfirm] = useState([]);
  const [deletedTeams, setDeletedTeams] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [FinalSave, setFinalSave] = useState(false);

  const { saveState, loading: addLoading } = useSave({
    url: `${applicationURL}/second/confirm/${code}`,
    goSave,
    setGoSave,
    newObject,
    popup: false,
  });

  useEffect(() => {
    if (newObjectConfirm && Object.keys(newObjectConfirm)?.length)
      setConfirmOpen(true);
  }, [newObjectConfirm]);

  const { saveState: finalState, loading: FinalLoading } = useSave({
    url: `${applicationURL}/second/${code}`,
    goSave: FinalSave,
    setGoSave: setFinalSave,
    newObject,
  });

  useEffect(() => {
    console.log(newObjectConfirm);
    if (newObjectConfirm && Object.keys(newObjectConfirm)?.length)
      setConfirmOpen(true);
  }, [newObjectConfirm]);

  useEffect(() => {
    if (!addLoading && saveState?.dataRes?.data) {
      setNewObjectConfirm(saveState?.dataRes?.data?.data);
    }
  }, [addLoading, goSave, saveState]);

  useEffect(() => {
    if (finalState?.dataRes?.data && !FinalLoading) {
      setConfirmOpen(false);
      navigate(`/check/${code}`);
    }
  }, [FinalLoading, finalState]);

  const CheckApp = () => {
    if (code)
      Fetch(
        `${applicationURL}/second/formData/${code}`,
        setApplication,
        null,
        setLoading
      );
  };

  useEffect(() => {
    if (code) CheckApp();
  }, [code]);

  useEffect(() => {
    document.title = "International Robot Challenge - Last Stage Application";
  }, []);
  return (
    <LastStageTemplate
      newObject={newObject}
      setNewObject={setNewObject}
      setGoSave={setGoSave}
      setFinalSave={setFinalSave}
      application={application}
      loading={loading}
      setLoading={setLoading}
      categoriesList={application?.competetion?.categories ?? []}
      confirmOpen={confirmOpen}
      setConfirmOpen={setConfirmOpen}
      newObjectConfirm={newObjectConfirm}
      FinalLoading={FinalLoading}
      addLoading={addLoading}
      setDeletedTeams={setDeletedTeams}
      deletedTeams={deletedTeams}
    />
  );
};

export default LastStagePage;
