import React, { useEffect } from "react";
import PuckCollectTemplate from "../../../Templetes/Categories/Details/PuckCollect/PuckCollect.template";

const PuckCollectPage = () => {
  useEffect(() => {
    document.title = "Internatioal Robot Challenge - Puck Collect";
  }, []);
  return <PuckCollectTemplate />;
};

export default PuckCollectPage;
