import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import "./Numbers.css";
import { Images } from "../../../../helpers/Images";

const Numbers = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "70px 120px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "#fff",
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: "500",
          color: "primary.darker",
        }}
      >
        IRC 2023 Numbers
      </Typography>
      <Box
        sx={{
          height: "3px",
          width: "150px",
          backgroundColor: "primary.light",
          margin: "auto",
        }}
      />
      <Typography
        variant={"p"}
        sx={{
          textAlign: "center",
          color: "primary.darker",
          maxWidth: "750px",
          margin: "30px auto",
          fontSize: "17px",
        }}
      >
        From the start in 2021 InternationalRobotChallenge® has grown fast,
        including more academies and more children and young people each year.
      </Typography>
      <Grid
        container
        justifyContent={"center"}
        style={{ maxWidth: "1000px", margin: "auto" }}
      >
        {[
          { image: Images.team, number: "450+", title: "Team" },
          { image: Images.student, number: "1400+", title: "Student" },
          { image: Images.govern, number: "19+", title: "Governorate" },
          { image: Images.academy, number: "86+", title: "Academy/School" },
          { image: Images.category, number: "37", title: "Category" },
        ].map((item, i) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={i}>
            <Box
              sx={{
                border: "3px solid",
                borderColor: "primary.light",
                borderRadius: "10px",
                padding: "20px 10px",
                textAlign: "center",
                margin: "10px",
              }}
            >
              <img
                alt={i}
                src={item.image}
                style={{
                  height: "80px",
                  filter:
                    "invert(13%) sepia(48%) saturate(5796%) hue-rotate(350deg) brightness(103%) contrast(93%)",
                }}
              />
              <Typography
                variant={"h3"}
                sx={{
                  textAlign: "center",
                  fontSize: "45px",
                  fontWeight: "500",
                  color: "primary.darker",
                }}
              >
                {item?.number}
              </Typography>
              <Typography
                variant={"h4"}
                sx={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "primary.light",
                }}
              >
                {item?.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Numbers;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
