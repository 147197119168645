import React from "react";
import CategoriesCarousel from "../../Components/organisms/Home/CategoriesCarousel/CategoriesCarousel";
import CountDown from "../../Components/organisms/Home/CountDown/CountDown";
import Highlights from "../../Components/organisms/Home/Highlights/Highlights";
import Numbers from "../../Components/organisms/Home/Numbers/Numbers";
import Reviews from "../../Components/organisms/Home/Reviews/Reviews";
import { Images } from "../../helpers/Images";
import CompetitionsSection from "../../Components/organisms/Home/Competitions/Competitions";
import LogoCarouse from "../../Components/organisms/Home/LogoCarousel/LogoCarousel";

const HomeTemplate = () => {
  return (
    <>
      <CountDown />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          src={Images.cover}
          style={{ width: "100%", maxHeight: "600px", objectFit: "cover" }}
        />
      </div>
      <Numbers />
      <CategoriesCarousel />
      <CompetitionsSection />
      <LogoCarouse />
      <Highlights />
      <Reviews />
    </>
  );
};

export default HomeTemplate;
